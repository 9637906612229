import { captureException, withScope } from "@sentry/node"
import ReactPixel from "react-facebook-pixel"

const isServer = typeof window === "undefined"
const isProduction = process.env.NEXT_PUBLIC_ENVIRONMENT === "production"

const PIXEL_ID = "2699947036936633"

export function init() {
  const options = { autoConfig: true, debug: !isProduction }
  setTimeout(() => {
    ReactPixel.init(PIXEL_ID, {}, options)
    sendPageView()
  }, 3000)
}

export function sendPageView() {
  if (isServer) return
  try {
    ReactPixel.pageView()
  } catch (error) {
    sendToSentry(error)
  }
}

export function sendClick() {
  try {
    ReactPixel.track("Click", {})
  } catch (error) {
    sendToSentry(error)
  }
}

function sendToSentry(error) {
  withScope(scope => {
    scope.setFingerprint(["FbPixel error"])
    captureException(error)
  })
}
