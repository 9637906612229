import { Router } from "next/router"
import { init as initSentry } from "@/scripts/Sentry"
import { init as initGTM, sendPageView as sendGAPageView } from "@/scripts/GoogleAnalytics"
import { init as initFB, sendPageView as sendFBPageView } from "@/scripts/FacebookPixel"

const isServer = typeof window === "undefined"
let lastPath = !isServer && window.location.pathname

export default function initializers() {
  initSentry()

  if (isServer) return
  const { pathname } = window.location

  console.debug("Initialize")

  initGTM(pathname)
  initFB()

  Router.events.on("routeChangeComplete", url => {
    const path = url.split("?")[0]
    if (lastPath === path) return
    lastPath = path
    sendGAPageView(path)
    sendFBPageView()
  })
}
