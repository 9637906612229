import { captureException, withScope } from "@sentry/node"

const canTrack = process.env.NODE_ENV === "production"
const isServer = typeof window === "undefined"
const isFunction = fn => typeof fn === "function"

export const GA_TRACKING_ID = "G-QY8KJL9NFV"
export const GTAG_ID = "AW-821088295"

export function init(pathname) {
  if (!isFunction(window.gtag)) return
  window.gtag("js", new Date())

  window.gtag("config", GA_TRACKING_ID)
  window.gtag("config", GTAG_ID)
  if (pathname) sendPageView(pathname)
}

export function sendEvent(eventName, eventParameters) {
  if (!canTrack) console.debug(`GA event: "${eventName}" with params ${JSON.stringify(eventParameters)}`)
  if (isServer || !canTrack) return
  try {
    window.gtag("event", eventName, eventParameters)
  } catch (error) {
    withScope(scope => {
      scope.setFingerprint(["Gtag error"])
      captureException(error)
    })
  }
}

export function sendPageView(url) {
  if (isServer) return
  if (!canTrack) {
    console.debug(`GA page view: ${url}`)
    return
  }
  try {
    window.gtag("config", GA_TRACKING_ID, { page_path: url })
  } catch (error) {
    withScope(scope => {
      scope.setFingerprint(["Gtag error"])
      captureException(error)
    })
  }
}
